import React from 'react';
import './css/Banner3.scss';
import paper from "../paper.svg";
import paper1 from "../facebook.svg";
import paper2 from "../instagram.svg";
import paper3 from "../twitter.svg";
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';
const Banner3=()=>
{
    const mailTo = () => {
        window.open('mailto:support@iinvsys.com')
    }
    return(
        <div className="pos" id={"contact"}>
            
               <h2 className="pos2"><Flip left cascade>Contact Us</Flip></h2>
               <p className="pos3" onClick={mailTo}><Fade bottom cascade delay={300}><img src={paper} alt=""/>Support@iinvsys.com</Fade></p>
               <Fade left cascade delay={500}>  
               <div className="socialmedia">  
               
                <img src={paper1} className="img1" alt=""/>
                <img src={paper2} className="img2" alt=""/>
                <img src={paper3} className="img3" alt=""/>
                
               </div>
               </Fade>    
            
        </div>
    
    );

}
export default Banner3;