
import './App.scss';
import { useRef, Component, React } from "react";
import Navbar from './Components/Navbar';
import Banner from './Components/Banner';
import Banner1 from './Components/Banner1';
import Banner2 from './Components/Banner2';
import Banner3 from './Components/Banner3';
import Banner4 from './Components/Banner4';
class App extends Component {
  constructor(props) {
    super(props);
  //   this.HomeView = React.createRef();
  //   this.AboutView = React.createRef();
  //  this.CareersView = React.createRef();
  }
  componentDidMount(){
    
  }
   ScrollToValue = (value) => {
    if(value == 'Home')
    {
      // console.log(this.HomeView.current.scrollIntoView());
    // HomeView.current.scrollIntoView();
    }
    else if(value == 'About')
    {
      // console.log(this.AboutView.current);
    // AboutView.current.scrollIntoView();
    }
    else if(value == 'Careers')
    {
      // console.log(this.CareersView.current);
    // CareersView.current.scrollIntoView();
    }
  }
  render() {
  return (
          <div className="App">
          <Navbar scrollTo = {this.ScrollToValue}/>
          <Banner />
          <Banner1 />
          <Banner2/>
          <Banner3/>
          <Banner4/>
          </div>
         );
};
}

export default App;
