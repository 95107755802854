import React from 'react';
import './css/Navbar.scss';
import logo from '../assets/images/IINVSYS.svg'
import ScrollIntoView from 'react-scroll-into-view'
const Navbar=(props)=>
{
    

      return(
          <div className="navbar">
              <div className="Logo"><img alt="Logo" src={logo}></img></div>
              <div className="NavItems">
              <ScrollIntoView selector="#home">
                  <div>Home</div>
                  </ScrollIntoView>
                  <ScrollIntoView selector="#about">
                  <div>About</div>
                  </ScrollIntoView>
                  <ScrollIntoView selector="#careers">
                 <div onClick={props.scrollTo('Careers')}>Careers</div>
                 </ScrollIntoView>
                 <ScrollIntoView selector="#contact">
                 <div>Contact Us</div>
                 </ScrollIntoView>
              
              </div>
          </div>
      );  
    
}
export default Navbar;