import React, {useState} from 'react';
import './css/Banner2.scss';
import pic6 from "../assets/images/careers.png";
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';
import './css/Banner4.scss';
import paper2 from "../paper2.svg";
import emailjs from 'emailjs-com';

const JobApply=(props)=>
{
    function sendEmail(e) {
        e.preventDefault();
    
        emailjs.sendForm('service_vlyrs7q', 'template_hut4a7t', e.target, 'user_GbqGLD0rsNsdNvhOkSlw3')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
      }
return(<>
<div className="ModalWrapper">
<div className="form" >
         <div className="top">
             <p>Join Us</p>
         </div>
         <form enctype="multipart/form-data" method="post" onSubmit={sendEmail}>
             <div>
                 <label>Name</label>
                 <input type="text"
                 className="name"
                 name="user_name"
                 />
             </div> 
             <div>
                 <label>Email</label>
                 <input type="email"
                 name="user_email"
                 className="email"/>
                
             </div> 
             <div>
                 <label>Mobile</label>
                 <input type="tel"
                 name="contact_number"
                  className="mobile"/>
               
             </div>
             <div>
                 <label>Message</label>
                 <textarea type="text"
                 name="message"
                  className="message"/>
               
             </div>    
             <div>
                 <label>Upload Your Resume</label>
                 <input type="file" name="my_file" />
               
             </div>    
            
             <div className="Buttons">
                 <button type="submit"> <img src={paper2} alt=""/>Apply Now</button>
                 <button className="cancel" onClick ={()=> props.setModal(false)}>Cancel</button>
             </div>             
         </form>
     </div>
     </div>
     
</>)
}
export default JobApply;