import React from 'react';
import './css/Banner1.scss';
import pic2 from "../assets/images/picture1.png";
import pic3 from "../assets/images/picture2.png";
import pic4 from "../assets/images/picture3.png";
import pic5 from "../assets/images/picture4.png";
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';

const Banner1=(props)=>
{
   const mailTo = () => {
        window.open('mailto:support@iinvsys.com')
    }
    return(
       
      <div className = "banner1" id={"about"}>
          {/* <img src={pic1} alt="" width="300px"/> */}
          <Fade duration={2000} delay={100}>
          <div className="banner2">
          
              <div className= "PictureRow">
             <div className="picture1">
             <Fade cascade top duration={1000}>
              <img src={pic2} alt=""/>
              </Fade>
              </div>
              <div className="picture2">
              <Fade cascade right duration={1000} >
              <img src={pic3}  alt=""/>
              </Fade>
              </div>
              </div>
              <div className= "PictureRow">
              <div className="picture3">
              <Fade cascade left duration={1000}>
              <img src={pic4} alt=""/>
              </Fade>
              </div>
              <div className="picture4">
              <Fade cascade bottom duration={1000}>
              <img src={pic5} alt=""/>
              </Fade>
              </div>
              </div>
              <div className="About">
              <h1><Flip left cascade delay={1000}>About IINVSYS</Flip></h1>
              <p><Flip left cascade delay={1200}>For more information. Please write us to</Flip></p>
              <p className="got" onClick={mailTo}><Flip bottom cascade delay={1400}>support@iinvsys.com</Flip></p>
              </div>
             
          </div>
          </Fade>
      </div> 
     
        
          );
        
} 
  export default Banner1; 