import React from 'react';
import './css/Banner4.scss';
import paper2 from "../paper2.svg";
import emailjs from 'emailjs-com';

const Banner4=()=>
{
    function sendEmail(e) {
        e.preventDefault();
    
        emailjs.sendForm('service_vlyrs7q', 'template_hut4a7t', e.target, 'user_GbqGLD0rsNsdNvhOkSlw3')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
      }

    return(
        <div className="foot">
            <div className="text">
                
                <p>Copyright © 2021 IINVSYS Pvt. Ltd. All rights reserved.</p>
            </div>
            <div className="form" onSubmit={sendEmail}>
                <div className="top">
                    <p>Know About Us</p>
                </div>
                <form>
                    <div>
                        <label>Name</label>
                        <input type="text"
                        className="name"
                        name="user_name"
                        />
                    </div> 
                    <div>
                        <label>Email</label>
                        <input type="email"
                        name="user_email"
                        className="email"/>
                       
                    </div> 
                    <div>
                        <label>Mobile</label>
                        <input type="tel"
                        name="contact_number"
                         className="mobile"/>
                      
                    </div>
                    <div>
                        <label>Message</label>
                        <textarea type="text"
                        name="message"
                         className="message"/>
                      
                    </div>    
                    <div>
                        <button type="submit"> <img src={paper2} alt=""/> Send</button>
                    </div>             
                </form>
            </div>
        </div>
    );

}
export default Banner4;