import React, {useState} from 'react';
import './css/Banner2.scss';
import pic6 from "../assets/images/careers.png";
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';
import './css/Banner4.scss';
import paper2 from "../paper2.svg";
import emailjs from 'emailjs-com';
import JobApply from './JobApply';
const Banner2=(props)=>
{
    const [showModal, setModal] = useState(false);
    const mailTo = () => {
        window.open('mailto:hr@iinvsys.com')
    }
    return(
        <>
        <div className="group1" id={"careers"}>
            <Fade duration={1000}>
                <>
         {<div className="group2">
                 <h1><Flip left cascade>Careers</Flip></h1>
                 <p onClick={mailTo}>To join our team, Please send your resume to hr@iinvsys.com</p>
                 {/* <button className="button" onClick={()=> setModal(true)}><Fade bottom delay={1000}>Apply Now</Fade></button> */}
                
            </div>}
           
            </>
            </Fade>
            <div className="grpAbsolute">
            <img src={pic6} alt=""/>
            </div>
        </div>
         {showModal && <JobApply setModal = {setModal}/>}
     </>
    );

}
export default Banner2;