import React from 'react';
import './css/Banner.scss';
import pic from "../assets/images/bannerImg.png";
import Flip from 'react-reveal/Flip';
import Fade from 'react-reveal/Fade';


const Banner=(props)=>
{
    return(
      
      <div className="banner" id={"home"}>
          <div className="Text">
          <Fade left duration={2000}><span>Pioneering the art of Analyzing and Learning the Data that we live-in today, for a better and informed living tomorrow</span>
            </Fade>
          </div>
          <Fade duration={2000}>
        <div className="MainImage">
          <img src={pic} alt=""/>
          </div>
          </Fade>
        
          
      </div> 
     
        
          );
        
} 
  export default Banner; 